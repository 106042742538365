import React, { useContext } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'gatsby';

import animationData from '@images/svg/lotties/cow.json';
import { SEO } from '@components';
import styles from './404.module.scss';

import { NewsletterModalContext } from '../context/NewsletterModalContextProvider';

const NotFoundPage = () => {
  const { toggleNewsletterModalHandler } = useContext(NewsletterModalContext);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <SEO title='404: Not found' />
      <div className={styles.descriptionContainer}>
        <p>
          Oops! This page is not found. <br />
          It might be an old link or maybe it moved.
          <br />
          Or did you type the wrong URL on purpose?
        </p>
        <div className={styles.buttonsContainer}>
          <Link to='/'>
            <button className={styles.subscribeButton}>
              Return to Homepage
            </button>
          </Link>
          <button
            onClick={toggleNewsletterModalHandler}
            className={styles.subscribeButton}
          >
            Subscribe to Newsletter
          </button>
          {/* <button className={styles.subscribeButton}> Read about Tech</button> */}
          {/* <button className={styles.subscribeButton}> Get Feebies </button> */}
        </div>

        <div className={styles.lottieSection}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
